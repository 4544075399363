.tourCardContainer{
    overflow:hidden;
    /* border-radius: 15px;
    background-color: #697739;
    margin: 15px; */
}
.tourCard{
    display: block;
    position: relative;
    margin:15px;
    /* min-width:33.3333%; */
    overflow: hidden;
    transition: all 0.5s;
}
.tourCard:hover{
    margin: 0px;
}
.tourCard img{
    width:auto;
    height: 600px;
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
}
.cardCaption{
    width:100%;
    height: auto;
    position: absolute;
    bottom: 0;
    padding-bottom: 30px;
    transition: all 0.5s;
}
.cardCaption::before{
    content: '';
    background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0, 0, 0, 0.79) 0%, rgba(11,31,35,0.4766281512605042) 40%, rgba(0,212,255,0) 100%);
    width:100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    transition: all 0.5s;
    z-index: 1;
}
.cardCaption h3{
    position: relative;
    padding: 15px;
    z-index: 2;
    color: #fff;
    font-weight: 100;
}
.cardCaption h3 a{
    color: #fff;
    text-decoration: none;
    border-bottom: none;
    transition: all 2s;
    font-family: 'Playfair Display', serif;
  font-size: 30px;
  font-weight: 100;
}

.cardCaption h3 a:hover{
    color: #fff;
    border-bottom: solid 3px #fff;
}