.logoContainer{
    display: block;
    width:80px;
    height: 80px;
    position: fixed;
    top: 25px;
    left: 25px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 101;
}
.logoContainer img{
    width:100%;
}
.mainNavCont{
    display: block;
    width:100vw;
    height: 100vh;
    position: fixed;
    background-color: #fff;
    z-index: 100;
}
.mainNavOpen{
    width:50px;
    height: 50px;
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 99;
    color: #BF9438;
    cursor: pointer;
}
.mainNav{
    display: block;
    width:100%;
    height: auto;
    list-style: none;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.mainNav > li {
    width:calc(100% - 50px);
    padding: 15px 25px ;
    clear: both;
    text-align: center;
    margin: 0;
}
.mainNav > li > a{
    text-decoration: none;
    color: #697739;
    font-size: 40px;
    font-weight: 100;
    cursor: pointer;
    transition: all 0.25s;
}
.mainNav > li > a:hover{
    text-decoration: underline;
}
.mainNavClose{
    width:50px;
    height: 50px;
    color: #697739;
    position: fixed;
    top: 15px;
    right:15px;
    z-index: 101;
    cursor: pointer;
}


