.videoWrapper {
	position: relative;
	padding-top: 0px;
    width:100%;
	height: 100vh;
  clear:both;
  overflow: hidden;
}

.videoWrapper>video{
    width:100%;
    height:auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@media screen and (max-width:1024px) {
  .videoWrapper>video{
    width:auto;
    height:100vh;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
}