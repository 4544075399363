:root{
    --background-dark: #2d3548;
    --text-light: rgba(255,255,255,0.6);
    --text-lighter: rgba(255,255,255,0.9);
    --spacing-s: 8px;
    --spacing-m: 16px;
    --spacing-l: 24px;
    --spacing-xl: 32px;
    --spacing-xxl: 64px;
    --width-container: 1200px;
  }
.singleTourSection{
    padding-bottom: 110px;
    z-index: 2;
}
.singleTourSection::before{
    content:'';
    position: absolute;
    top: 50px;
    right: 100px;
    bottom: 50px;
    left: 100px;
    background-color: rgba(1, 110, 59, 0.256);
    z-index: -1;
}
@media screen and (max-width: 800px) {
    .singleTourSection::before{
        display: none;
    }
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
* {
  margin: 0;
  padding: 0;
}

.card2{
    list-style: none;
    position: relative;
    background-color: transparent !important;
    border: none importat !important;
    border-radius: 0 !important;
    height: 300px;
  }
  
  .card2:before{
    content: '';
    color: transparent;
    display: block;
    padding-bottom: 150%;
    width: 100%;
    background-color: inherit;
  }
  /* .card2:after{
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 0;
  } */
  .card2__background{
    background-size: cover;
    background-position: center;
    border-radius: 0;
    bottom: 0;
    /* filter: brightness(0.75) saturate(1.2) contrast(0.85); */
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center;
    transform: scale(1) translateZ(0);
    transition: 
      filter 200ms linear,
      transform 200ms linear;
  }
  /* .card2:hover{
    z-index: 99;
  } */
  .card2:hover .card2__background{
    transform: scale(1.05) translateZ(0);
    filter: brightness(0.75) saturate(1.2) contrast(0.85);
  }
  
  .card2-grid:hover > .card2:not(:hover) .card2__background{
    filter: brightness(0.5) saturate(0) contrast(1.2) blur(20px);
  }
  
  .card2__content{
    left: 0;
    padding: var(--spacing-l);
    position: absolute;
    top: 0;
    right: 0;
    background: rgb(11,31,35);
background: linear-gradient(180deg, rgba(11,31,35,1) 0%, rgba(11,31,35,0.4766281512605042) 45%, rgba(11,31,35,0) 100%);
  }
  
  .card2__category{
    color: var(--text-light);
    font-size: 0.9rem;
    margin-bottom: var(--spacing-s);
    text-transform: uppercase;
  }
  
  .card2__heading{
    width: 100%;
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 100;
    text-shadow: 10px 10px 20px rgba(255, 255, 255, 0.2);
    line-height: 1.4;
    z-index: 1;
    /* word-spacing: 100vw; */
  }
  