.singleTourSection{
    padding-bottom: 110px;
    z-index: 2;
}
.singleTourSection::before{
    content:'';
    position: absolute;
    top: 50px;
    right: 100px;
    bottom: 50px;
    left: 100px;
    background-color: rgb(228, 214, 194);
    z-index: -1;
}
@media screen and (max-width: 800px) {
    .singleTourSection::before{
        display: none;
    }
}
.thumb_image{
    width: 100%;
}
@media screen and (max-width: 600px) {
    /* .thumb_image{
        margin-left: 50%;
        width: auto;
        height: 500px;
        transform: translate(-50%);
    } */
}
.image_row{
    margin-bottom: 30px;
}
.imagecont_round{
    width:200px;
    height: 200px;
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    margin: auto;
}
.image_absolute{
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.btn_sticky{
    display: block;
    width: 320px;
    position: fixed;
    top: 550px;
    right: 0px;
    z-index: 99;
}
.btn_sticky  button {
    width: 100%;
    border-radius: 0;
    height: 50px;
    background-color: #4e5c36;
    border: none;
}

@media screen and (max-width: 800px) {
    .btn_sticky{
        display: block;
        width: 100%;
        position: -webkit-sticky;
        position: sticky;
        top: 120px;
        z-index: 99;
        margin-bottom: 30px;
    }
    .btn_sticky > button {
        width: 100%;
        border-radius: 25px;
        height: 50px;
    }
}
.itine-cont{
    display: block;
    padding-bottom: 60px;
}
.offer-special{
    font-size: 18px;
    padding-top: 20px;
}