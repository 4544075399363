.singleTourCard{
    display: block;
    position: relative;
    overflow: hidden;
    max-height: 500px;
}
.singleTourCard img{
    width: auto;
    height: 500px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
